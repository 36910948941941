/* 登录页样式 */
.login-box{
    max-width: 370px;
    margin: 3rem auto;
    .login-title {
        font-weight: bold;
        font-size: 1.5rem;
    }
    ul.nav {
        margin-bottom: 1rem;
        li {
            width: 50%;
            margin: 0;
            padding: 0;
            a {
                border: 1px solid #ced4da;
                border-radius: 0;
                text-align: center;
                padding: 6px 0;
                display: block;
            }
            a:last-chile {
                border-left:0;
            }
            a:hover {
                background-color: #eee;
            }
        }
        li.active {
            a {
                color: #fff;
                background-color: #009966;
            }
        }
    }

    .login_form {
        .form-group {
            font-size: .875rem;
            label {
                color: #666;
                margin-bottom: 0.375rem;
            }
            .form-control {
                border-radius: 0;
                font-size: .875rem;
            }
            .input-group{
                input{
                    border-radius: 0;
                }
                .send-btn {
                    padding: 6px 10px;
                    font-size: .875rem;
                    font-weight: 400;
                    line-height: 1;
                    color: #555;
                    text-align: center;
                    background-color: #eee;
                    border: 1px solid #ccc;
                    margin-left: -1px;
                }
            }
            .phone_code {
                select {
                    max-width: 140px;
                }
            }
            .getpassword {
                float: right;
            }
        }
    }
    #oauth {
        li {
            display: inline-block;
            margin-right: 8px;
            font-size: .875rem;
            color: #333;
            vertical-align: middle;
            a {
                display: block;
                color: #333;
                i {
                    font-size: 1.5rem;
                }
            }
            a:hover {
                color: #009966;
            }
        }
    }
}

/* 注册页样式 */
